import React, { useState, useEffect } from 'react';

function Header({ onTabChange }) {
  const handleBannerClick = () => {
    onTabChange('feedback');
  };

  return (
    <>
      {/* Diagonal Beta Banner */}
      <div className="absolute top-0 right-0 z-50 w-72 h-72 overflow-hidden">
        <div 
          onClick={handleBannerClick}
          className="bg-[#364fc7] text-white text-center cursor-pointer
                    absolute top-12 right-[-60px] rotate-45 shadow-lg 
                    transform origin-center hover:bg-[#2b3f9e] transition-colors"
        >
          <div className="leading-tight py-1 px-16">
            <div className="text-base font-bold">Beta Release</div>
            <div className="text-[10px] font-medium">
              Reach out for feedback & suggestions
            </div>
          </div>
        </div>
      </div>

      {/* Main Header */}
      <header className="absolute top-0 left-0 w-full bg-[#852A45] text-white z-40">
        <div className="flex items-center justify-center py-4 pl-20 max-w-[100vw]">
          <div className="text-center w-full">
            <h1 className="text-2xl font-bold m-0 mb-1">
              Clinical Trial Landscape
            </h1>
            <p className="text-sm leading-relaxed text-gray-100">
              Based on studies registered on clinicaltrials.gov which were retrieved from the AACT database <br/>and structured and augmented by our AI agents framework
            </p>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header; 