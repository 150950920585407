import React from 'react';

const AboutTab = ({ onTabChange }) => {
  return (
    <div className="w-full max-w-3xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-semibold text-[#852A45] mb-6">About This Demo</h2>
        
        <div className="space-y-4 text-gray-700">
          <p>
          This is a demo to showcase a small part of our biomedical data layer, generated by an ensemble of AI agents.
          </p>



          <p>
          Data on assets in development are retrieved from the AACT database that contains all information (protocol and result data elements) about every study registered in ClinicalTrials.gov
          We have a set of AI agents which categorize and structure the data for specific research questions and enhance information on molecular targets and modalities.
          </p>
          
          
          <h2 className="text-2xl font-semibold text-[#852A45] mb-6">About Navis Bio</h2>
          <p>
            At <a href="https://navis-bio.com/" className="text-[#852A45] hover:underline">Navis Bio</a> we are building AI solutions to enhance decision making in drug development. We are building a comprehensive data layer merging different sources of data from early research to clinical development. Based on this we are developing models to predict future standard of care, competitive landscape, and best modality-target fit.
          </p>

          <div className="mt-8 flex justify-center">
            <button
              onClick={() => onTabChange('feedback')}
              className="bg-[#852A45] text-white px-6 py-2 rounded-lg hover:bg-[#6d2238] transition-colors duration-200"
            >
              Get in Touch
            </button>
          </div>

          <p className="mt-6 text-sm text-center text-gray-600">
            You can also reach us by email:{' '}
            <a href="mailto:nives.rombini@navis-bio.com" className="text-[#852A45] hover:underline">
              nives.rombini@navis-bio.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutTab;
