import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import WebSocketManager from '../services/websocket/WebSocketManager';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [trialLandscapeData, setTrialLandscapeData] = useState([]);
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [messageHandlers] = useState(new Map());

  useEffect(() => {
    if (!socket) {
      const wsUrl = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/api/ws/genes`;
      const newSocket = new WebSocketManager(wsUrl);
      
      newSocket.addMessageHandler('default', (data) => {
        console.log('Received initial connection data:', data);
      });

      newSocket.connect();
      setSocket(newSocket);
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    const handleTrialLandscapeData = (receivedData) => {
      if (!receivedData.data || !Array.isArray(receivedData.data)) {
        console.error('Invalid trial landscape data format:', receivedData);
        return;
      }
      setTrialLandscapeData(receivedData.data);
    };

    socket.addMessageHandler('trial_landscape', handleTrialLandscapeData);
    socket.send('trial_landscape', { genes: 'n/a', indication: 'n/a' });

    return () => {
      socket.removeMessageHandler('trial_landscape', handleTrialLandscapeData);
    };
  }, [socket, messageHandlers]);

  const sendFeedback = (feedbackMessage, userEmail, rowData) => {
    const feedbackData = {
      type: 'feedback',
      user_message: feedbackMessage,
      user_email: userEmail,
      metadata: {
        row_content: rowData
      },
      category: 'trial_landscape',
    };

    socket.send('feedback', feedbackData);
  };

  const sendMessage = useCallback((type, data) => {
    if (socket && socket.socket?.readyState === WebSocket.OPEN) {
      socket.send(type, data);
    }
  }, [socket]);

  const addMessageHandler = useCallback((type, handler) => {
    messageHandlers.set(type, handler);
  }, [messageHandlers]);

  const removeMessageHandler = useCallback((type) => {
    messageHandlers.delete(type);
  }, [messageHandlers]);

  return (
    <WebSocketContext.Provider
      value={{
        trialLandscapeData,
        sendFeedback,
        sendMessage,
        socket,
        isConnected,
        addMessageHandler,
        removeMessageHandler
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within WebSocketProvider');
  }
  return context;
};
