import React, { useMemo } from 'react';
import * as Slider from '@radix-ui/react-slider';

const DateRangeSlider = ({ startDate, endDate, onChange, minDate, maxDate }) => {
  const dateToValue = (date) => {
    return Math.floor((new Date(date) - new Date(minDate)) / (1000 * 60 * 60 * 24));
  };

  const valueToDate = (value) => {
    const date = new Date(minDate);
    date.setDate(date.getDate() + value);
    return date.toISOString();
  };

  const totalDays = useMemo(() => {
    return dateToValue(maxDate);
  }, [maxDate]);

  const currentValues = useMemo(() => [
    dateToValue(startDate),
    dateToValue(endDate)
  ], [startDate, endDate]);

  const handleSliderChange = (values) => {
    const newStart = valueToDate(values[0]);
    const newEnd = valueToDate(values[1]);
    
    onChange({
      start: values[0] !== currentValues[0] ? newStart : startDate,
      end: values[1] !== currentValues[1] ? newEnd : endDate
    });
  };

  return (
    <div className="px-2 py-4">
      <Slider.Root
        className="relative flex items-center select-none touch-none w-full h-5"
        value={currentValues}
        max={totalDays}
        step={1}
        minStepsBetweenThumbs={1}
        onValueChange={handleSliderChange}
      >
        <Slider.Track className="bg-gray-200 relative grow rounded-full h-[3px]">
          <Slider.Range className="absolute bg-[#852A45] rounded-full h-full" />
        </Slider.Track>
        <Slider.Thumb
          className="block w-5 h-5 bg-white border-2 border-[#852A45] rounded-full hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#852A45] focus:ring-offset-2"
          aria-label="Start date"
        />
        <Slider.Thumb
          className="block w-5 h-5 bg-white border-2 border-[#852A45] rounded-full hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#852A45] focus:ring-offset-2"
          aria-label="End date"
        />
      </Slider.Root>
    </div>
  );
};

export default DateRangeSlider; 