import React from 'react';
import DataTable from '../../tables/DataTable';
import { renderTableCell, handleExport } from '../helpers/trial_table_helpers';
import FeedbackButton from './FeedbackButton';

const tableColumns = [
  {
    key: 'feedback',
    label: '',
    visible: true,
    width: 50,
    minWidth: 50,
    renderCell: (value, rowId, expandedState, rowData) => (
      <FeedbackButton rowId={rowId} rowData={rowData} />
    ),
  },
  {
    key: 'investigational_agent',
    label: 'Compound Name',
    visible: true,
    width: 150,
    minWidth: 100,
  },
  {
    key: 'target',
    label: 'Target',
    visible: true,
    width: 150,
    minWidth: 100,
  },
  { 
    key: 'modality', 
    label: 'Modality', 
    visible: true, 
    width: 200,
    minWidth: 100,
  },
  { 
    key: 'phase', 
    label: 'Phase', 
    visible: true, 
    width: 100,
    minWidth: 100,
  },
  { 
    key: 'sponsor_names', 
    label: 'Sponsor', 
    visible: true, 
    width: 250,
    minWidth: 100,
  },
  {
    key: 'trials',
    label: 'Trial Information',
    visible: true,
    width: 200,
    minWidth: 100,
  },
];

const TrialDataTable = ({ trialData = [], loading, width = 800 }) => {
  // Debug log
  //console.log('Table data:', trialData);

  const renderCell = (column, value, rowId, expandedState, rowData) => {
    if (column.renderCell) {
      return column.renderCell(value, rowId, expandedState, rowData);
    }

    const cellId = `${rowId}-${column.key}`;
    return renderTableCell(column, value, cellId, expandedState, rowData);
  };

  return (
    <div style={{ width, marginBottom: '100px' }}>
      <DataTable
        data={trialData}
        columns={tableColumns}
        renderCell={renderCell}
        uniqueRowId="id"
        showExport={false}
      />
    </div>
  );
};

export default TrialDataTable;