import { useMemo } from 'react';

/**
 * Prepares the phase configuration by calculating radii and colors for each phase.
 */
export const preparePhaseConfig = (phases, Rmax) => {
  const totalArea = Math.PI * Rmax * Rmax;
  const approvedArea = (2.5 / 10) * totalArea;
  const outerPhaseArea = (2.5 / 10) * totalArea;

  const areaFractions = [
    0,
    approvedArea / totalArea,
    (approvedArea + outerPhaseArea) / totalArea,
    (approvedArea + 2 * outerPhaseArea) / totalArea,
    1,
  ];

  const radii = areaFractions.map((fraction) => Rmax * Math.sqrt(fraction));

  const getColorForPhase = (phase) => {
    switch (phase) {
      case 'PHASE1':
        return '#f0f0f0';
      case 'PHASE2':
        return '#e8e8e8';
      case 'PHASE3':
        return '#e0e0e0';
      case 'APPROVED':
        return '#d4edda';
      default:
        return '#ffffff';
    }
  };

  return phases.reduce((config, phase, i) => {
    config[phase] = {
      innerRadius: radii[i],
      outerRadius: radii[i + 1],
      color: getColorForPhase(phase),
    };
    return config;
  }, {});
};

/**
 * Transforms the trial data by cleaning it and organizing targets and modalities.
 */
// export const transformTrialData = (trialLandscapeData, targetCount) => {
//     // Map 'PHASE4' to 'APPROVED'
//     const cleanedData = trialLandscapeData.map((trial) => {
//       if (trial.phase === 'PHASE4') {
//         return { ...trial, phase: 'APPROVED' };
//       }
//       return trial;
//     });
  
//     // Group trials by target and compound
//     const aggregatedTrials = cleanedData.reduce((acc, trial) => {
//       const key = `${trial.target_short}-${trial.investigational_agent}`;
//       if (!acc[key]) {
//         acc[key] = {
//           ...trial,
//           trials: [trial]
//         };
//       } else {
//         acc[key].trials.push(trial);
//       }
//       return acc;
//     }, {});
  
//     // Extract top targets using the aggregated data
//     const targetCompoundsMap = Object.values(aggregatedTrials).reduce((map, trial) => {
//       const target = trial.target_short;
//       const agent = trial.investigational_agent;
//       if (
//         target &&
//         !['not target specific', 'not found', 'none', 'not applicable'].includes(target.toLowerCase()) &&
//         agent
//       ) {
//         if (!map[target]) {
//           map[target] = new Set();
//         }
//         map[target].add(agent.trim());
//       }
//       return map;
//     }, {});
  
//     // Rest of the function remains the same
//     const targetCounts = Object.entries(targetCompoundsMap).reduce(
//       (counts, [target, compoundsSet]) => {
//         counts[target] = compoundsSet.size;
//         return counts;
//       },
//       {}
//     );
  
//     const sortedTargets = Object.entries(targetCounts)
//       .sort((a, b) => b[1] - a[1])
//       .slice(0, targetCount)
//       .map(([target]) => target);
  
//     const angleStep = 360 / sortedTargets.length;
//     const updatedTargets = sortedTargets.map((target, index) => ({
//       name: target,
//       startAngle: index * angleStep,
//       endAngle: (index + 1) * angleStep,
//     }));
  
//     // Transform aggregated data
//     const transformedData = Object.values(aggregatedTrials)
//       .map((trial) => {
//         const targetObj = updatedTargets.find((t) => t.name === trial.target_short);
//         if (!targetObj) return null;
  
//         const modality = trial.modality || 'Unknown';
  
//         return {
//           ...trial,
//           target: trial.target_short,
//           modality,
//         };
//       })
//       .filter(Boolean);
  
//     const modalitiesSet = new Set(transformedData.map((agent) => agent.modality));
//     const modalitiesArray = Array.from(modalitiesSet);
  
//     const colors = [
//       '#FF6B6B', '#4ECDC4', '#FFB347', '#9B59B6', '#06D6A0',
//       '#118AB2', '#073B4C', '#EF476F', '#FFC43D', '#D4A373',
//     ];
  
//     const modalityColorMap = modalitiesArray.reduce((map, modality, index) => {
//       map[modality] = colors[index % colors.length];
//       return map;
//     }, {});
  
//     return {
//       updatedTargets,
//       transformedData,
//       modalitiesArray,
//       modalityColorMap,
//     };
//   };
  
/**
 * Calculates positions for each trial marker on the chart.
 */
const constrainToPhase = (x, y, centerX, centerY, innerRadius, outerRadius) => {
  const dx = x - centerX;
  const dy = y - centerY;
  const distance = Math.sqrt(dx * dx + dy * dy);
  
  // If point is outside the phase boundaries, move it back inside
  if (distance < innerRadius || distance > outerRadius) {
    const angle = Math.atan2(dy, dx);
    const newRadius = Math.min(Math.max(distance, innerRadius), outerRadius);
    return {
      x: centerX + Math.cos(angle) * newRadius,
      y: centerY + Math.sin(angle) * newRadius
    };
  }
  
  return { x, y };
};

// Update the getDistanceFromPhaseLabel function to create a better forbidden zone
const getDistanceFromPhaseLabel = (x, y, centerX, centerY, phaseConfig) => {
  let minDistance = Infinity;
  
  Object.entries(phaseConfig).forEach(([phase, config]) => {
    if (phase === 'center') return;
    
    const { innerRadius, outerRadius } = config;
    const labelRadius = (innerRadius + outerRadius) / 2;
    
    // Define the forbidden zone rectangle dimensions
    const labelWidth = 60;  // Width of phase label
    const labelHeight = 20; // Height of phase label
    const padding = 10;     // Additional padding around label
    
    // Calculate the four corners of the forbidden zone
    const labelCenterX = centerX;
    const labelCenterY = centerY - labelRadius;
    
    const left = labelCenterX - (labelWidth / 2) - padding;
    const right = labelCenterX + (labelWidth / 2) + padding;
    const top = labelCenterY - (labelHeight / 2) - padding;
    const bottom = labelCenterY + (labelHeight / 2) + padding;
    
    // Check if point is inside the forbidden zone
    if (x >= left && x <= right && y >= top && y <= bottom) {
      minDistance = 0; // Point is inside forbidden zone
    } else {
      // Calculate distance to nearest edge of forbidden zone
      const dx = Math.max(left - x, x - right, 0);
      const dy = Math.max(top - y, y - bottom, 0);
      const distance = Math.sqrt(dx * dx + dy * dy);
      minDistance = Math.min(minDistance, distance);
    }
  });
  
  return minDistance;
};

// Add this helper function to check distance from all labels
const getMinDistanceFromLabels = (x, y, centerX, centerY, phaseConfig, existingPositions) => {
  // Get minimum distance from phase labels
  const phaseDistance = getDistanceFromPhaseLabel(x, y, centerX, centerY, phaseConfig);
  
  // Get minimum distance from existing trial labels
  let minTrialLabelDistance = Infinity;
  existingPositions.forEach(pos => {
    // Check distance from both marker and its label
    const markerDx = x - pos.x;
    const markerDy = y - pos.y;
    const markerDistance = Math.sqrt(markerDx * markerDx + markerDy * markerDy);
    
    // Approximate label position (we'll use the same logic as in TrialMarkers component)
    const labelPositions = [
      { x: pos.x, y: pos.y - 15 },  // top
      { x: pos.x, y: pos.y + 15 },  // bottom
      { x: pos.x - 15, y: pos.y },  // left
      { x: pos.x + 15, y: pos.y }   // right
    ];

    labelPositions.forEach(labelPos => {
      const labelDx = x - labelPos.x;
      const labelDy = y - labelPos.y;
      const labelDistance = Math.sqrt(labelDx * labelDx + labelDy * labelDy);
      minTrialLabelDistance = Math.min(minTrialLabelDistance, labelDistance);
    });
  });

  return Math.min(phaseDistance, minTrialLabelDistance);
};

export const calculateTrialPositions = (trialData, targets, phaseConfig, centerX, centerY) => {
  const trialsByTargetPhase = trialData.reduce((acc, agentData) => {
    const key = `${agentData.target}-${agentData.phase}`;
    if (!acc[key]) acc[key] = [];
    acc[key].push(agentData);
    return acc;
  }, {});

  const finalPositions = [];
  
  Object.values(trialsByTargetPhase).forEach(trialsInGroup => {
    trialsInGroup.forEach((agentData, index) => {
      const target = targets.find((t) => t.name === agentData.target);
      if (!target) return null;

      const phase = agentData.phase;
      const { innerRadius, outerRadius } = phaseConfig[phase] || {};
      if (!phaseConfig[phase]) return null;

      // Calculate position within target's angular boundaries
      const angularRange = target.endAngle - target.startAngle;
      const angleStep = angularRange / (trialsInGroup.length + 1);
      const angle = target.startAngle + (angleStep * (index + 1));
      
      // Calculate radial position
      const radialRange = outerRadius - innerRadius;
      const radius = innerRadius + (radialRange * 0.5); // Center in phase ring

      // Convert to cartesian coordinates
      const radians = (angle * Math.PI) / 180;
      const x = centerX + Math.cos(radians) * radius;
      const y = centerY + Math.sin(radians) * radius;

      finalPositions.push({
        ...agentData,
        x,
        y,
        angle,
        targetStartAngle: target.startAngle,
        targetEndAngle: target.endAngle
      });
    });
  });

  return finalPositions;
};

/**
 * Adjusts positions to minimize overlaps between markers
 */
const adjustForCollisions = (positions, phaseConfig, centerX, centerY) => {
  const MIN_DISTANCE = 30;
  const MAX_ITERATIONS = 5;
  let currentPositions = [...positions];

  for (let iteration = 0; iteration < MAX_ITERATIONS; iteration++) {
    let hasChanges = false;

    for (let i = 0; i < currentPositions.length; i++) {
      for (let j = i + 1; j < currentPositions.length; j++) {
        const p1 = currentPositions[i];
        const p2 = currentPositions[j];

        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < MIN_DISTANCE) {
          hasChanges = true;
          const angle = Math.atan2(dy, dx);
          const moveDistance = (MIN_DISTANCE - distance) / 2;

          // Move points apart and constrain to phase boundaries
          const newP1 = {
            ...p1,
            x: p1.x - Math.cos(angle) * moveDistance,
            y: p1.y - Math.sin(angle) * moveDistance
          };

          const newP2 = {
            ...p2,
            x: p2.x + Math.cos(angle) * moveDistance,
            y: p2.y + Math.sin(angle) * moveDistance
          };

          // Constrain both points to their respective phase boundaries
          const { x: x1, y: y1 } = constrainToPhase(
            newP1.x,
            newP1.y,
            centerX,
            centerY,
            phaseConfig[p1.phase].innerRadius,
            phaseConfig[p1.phase].outerRadius
          );

          const { x: x2, y: y2 } = constrainToPhase(
            newP2.x,
            newP2.y,
            centerX,
            centerY,
            phaseConfig[p2.phase].innerRadius,
            phaseConfig[p2.phase].outerRadius
          );

          currentPositions[i] = { ...newP1, x: x1, y: y1 };
          currentPositions[j] = { ...newP2, x: x2, y: y2 };
        }
      }
    }

    if (!hasChanges) break;
  }

  return currentPositions;
};

/**
 * Adjusts angles to avoid overlap with phase labels at top and bottom
 */
const adjustAngleForLabels = (angle) => {
  const LABEL_AVOID_RANGE = 35; // Increased range to avoid
  
  // Normalize angle to 0-360
  let normalizedAngle = angle % 360;
  if (normalizedAngle < 0) normalizedAngle += 360;

  // Adjust angles near 90° (top) and 270° (bottom)
  const criticalAngles = [90, 270];
  
  for (const criticalAngle of criticalAngles) {
    if (Math.abs(normalizedAngle - criticalAngle) < LABEL_AVOID_RANGE) {
      const diff = normalizedAngle - criticalAngle;
      const shift = LABEL_AVOID_RANGE - Math.abs(diff);
      // Add extra shift to ensure points move well away from labels
      const extraShift = 10;
      return normalizedAngle + (diff < 0 ? -(shift + extraShift) : (shift + extraShift));
    }
  }

  return normalizedAngle;
};
