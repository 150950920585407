import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { preparePhaseConfig, calculateTrialPositions } from '../helpers/target_trial_phase_plot_helpers';
import { useLabelSimulation } from '../hooks/useLabelSimulation';
import {
  CircularSections,
  RadialLines,
  PhaseLabels,
  Legend,
  TargetLabels,
  TrialMarkers,
  CenterCircle,
} from './TargetTrialPhasePlotComponents';
import { WebSocketContext } from '../../../context/WebSocketContext';
import FeedbackModal from '../../common/FeedbackModal';

const phases = ['PHASE3', 'PHASE2', 'PHASE1'];

const TargetTrialPhasePlot = ({
  trialData,
  loading,
  targets,
  targetCount,
  modalities,
  modalityColors,
  containerWidth = 800
}) => {
  const [hoveredTrial, setHoveredTrial] = useState(null);
  const [clickedTrial, setClickedTrial] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackData, setFeedbackData] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const { sendFeedback } = useContext(WebSocketContext);

  const handleFeedbackClose = (success) => {
    setShowFeedbackModal(false);
    if (success) {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 1000);
    }
  };

  const handleSuggestChanges = (data) => {
    setFeedbackData(data);
    setShowFeedbackModal(true);
  };

  // Calculate space needed for labels
  const FIXED_LABEL_WIDTH = 80;
  const SAFETY_MARGIN = 10;
  const TOP_MARGIN = 30;
  const BOTTOM_MARGIN = 30;
  
  // Use full width for the plot
  const legendWidth = containerWidth * 0.15;
  const plotToLegendSpace = 60;
  const plotWidth = containerWidth - legendWidth - plotToLegendSpace;
  
  const width = containerWidth;
  const height = containerWidth * 0.8;
  
  // Center the plot in the available plot area (excluding legend)
  const centerX = plotWidth / 2;
  const centerY = height / 2;
  
  // Maximize the radius to use available space
  const Rmax = Math.min(
    plotWidth / 2.2,
    (height - TOP_MARGIN - BOTTOM_MARGIN) / 2.0
  );

  const phaseConfig = useMemo(() => preparePhaseConfig(phases, Rmax), [phases, Rmax]);
  const trials = useMemo(() => {
    //console.log('TargetTrialPhasePlot - Received trialData:', trialData?.length || 0, 'trials');
    //console.log('TargetTrialPhasePlot - Received targets:', targets?.length || 0, 'targets');
    return calculateTrialPositions(trialData, targets, phaseConfig, centerX, centerY);
  }, [trialData, targets, phaseConfig, centerX, centerY]);

  const targetPositions = useMemo(() => {
    return targets.map(target => ({
      x: centerX + Math.cos(target.startAngle * Math.PI / 180) * (Rmax + 35),
      y: centerY + Math.sin(target.startAngle * Math.PI / 180) * (Rmax + 35),
      target
    }));
  }, [targets, centerX, centerY, Rmax]);

  const labelPositions = useLabelSimulation(targetPositions);

  // Handle click outside of trials
  const handleSvgClick = useCallback((event) => {
    // Get the clicked element
    const clickedElement = event.target;
    
    // Check if the clicked element is part of a trial marker, tooltip, or tooltip content
    const isTrialElement = clickedElement.closest('.trial-marker, .tooltip-container');
    
    // If click is not on a trial marker or tooltip, clear the clicked trial
    if (!isTrialElement) {
      setClickedTrial(null);
    }
  }, []);

  const SuccessMessage = () => (
    <div 
      className="fixed inset-0 flex items-center justify-center"
      style={{ zIndex: 99999999999 }}
      onClick={() => setShowSuccess(false)}
    >
      <div 
        className="bg-green-50 text-green-700 px-4 py-2 rounded-lg shadow-lg border border-green-200 flex items-center gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <span className="font-medium">Suggestion sent. Thank you!</span>
      </div>
    </div>
  );

  // Add this new useMemo to filter modalities
  const activeModalities = useMemo(() => {
    if (!trialData?.length) return [];
    
    // Get unique modalities from the trial data
    const activeModalitySet = new Set(trialData.map(trial => trial.modality));
    
    // Return only the modalities that are present in the data
    return modalities.filter(modality => activeModalitySet.has(modality));
  }, [trialData, modalities]);

  // Check loading state first
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 w-full">
        <div className="text-gray-500 text-lg">
          Loading trial landscape data...
        </div>
      </div>
    );
  }

  // Then check for no data
  if (!trialData?.length || !targets?.length) {
    return (
      <div className="flex justify-center items-center h-64 text-gray-500">
        No target data available for the selected criteria
      </div>
    );
  }

  return (
    <div className="relative w-full mt-8 mb-16">
      <div style={{ 
        position: 'relative', 
        overflow: 'visible', 
        width: width,
        height: height,
        marginBottom: BOTTOM_MARGIN
      }}>
        <svg
          width={width}
          height={height}
          style={{ 
            overflow: 'visible', 
            position: 'relative', 
            zIndex: 1 
          }}
          onClick={handleSvgClick}
        >
          <CircularSections
            centerX={centerX}
            centerY={centerY}
            phaseConfig={phaseConfig}
          />
          <RadialLines
            targets={targets}
            centerX={centerX}
            centerY={centerY}
          />
          <PhaseLabels
            centerX={centerX}
            centerY={centerY}
            phaseConfig={phaseConfig}
          />
          <TargetLabels
            targets={targets}
            centerX={centerX}
            centerY={centerY}
            Rmax={Rmax}
            labelPositions={labelPositions}
          />
          <TrialMarkers
            trials={trials}
            hoveredTrial={hoveredTrial}
            clickedTrial={clickedTrial}
            setHoveredTrial={setHoveredTrial}
            setClickedTrial={setClickedTrial}
            onSuggestChanges={handleSuggestChanges}
            modalityColors={modalityColors}
          />
          <CenterCircle
            centerX={centerX}
            centerY={centerY}
            phaseConfig={phaseConfig}
          />
        </svg>

        <Legend
          x={plotWidth + plotToLegendSpace}
          y={TOP_MARGIN}
          width={legendWidth}
          modalities={activeModalities}
          modalityColors={modalityColors}
          style={{ position: 'absolute', zIndex: 2 }}
        />
      </div>

      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={handleFeedbackClose}
        rowData={feedbackData}
        category="target_compound_plot"
        sendFeedback={sendFeedback}
      />

      {showSuccess && <SuccessMessage />}
    </div>
  );
};

export default TargetTrialPhasePlot;