class WebSocketManager {
  constructor(url) {
    this.url = url;
    this.socket = null;
    this.messageHandlers = new Map();
    this.retryCount = 0;
    this.maxRetries = 3;
    this.retryDelay = 3000;
    this.reconnectTimeout = null;
    this.pendingMessages = [];
  }

  connect() {
    try {
      ////console.log('WebSocketManager: Attempting to connect to WebSocket...');
      this.socket = new WebSocket(this.url);

      this.socket.onopen = () => {
        //console.log('WebSocketManager: Connection established');
        this.retryCount = 0;
        
        // Send any pending messages
        while (this.pendingMessages.length > 0) {
          const { type, data } = this.pendingMessages.shift();
          this.send(type, data);
        }
      };

      this.socket.onmessage = (event) => {
        const message = event.data;
        try {
          //console.log('WebSocketManager: Received message:', message);
          const data = JSON.parse(message);
          const handlers = this.messageHandlers.get(data.type) || [];
          //console.log(`WebSocketManager: Found ${handlers.length} handlers for type "${data.type}"`);
          handlers.forEach(handler => handler(data));
        } catch (error) {
          console.error('WebSocketManager: Error handling message:', error);
        }
      };

      this.socket.onclose = (event) => {
        //console.log('WebSocketManager: Connection closed', event.code, event.reason);
        
        if (this.retryCount < this.maxRetries) {
          //console.log(`WebSocketManager: Attempting to reconnect... (${this.retryCount + 1}/${this.maxRetries})`);
          this.reconnectTimeout = setTimeout(() => {
            this.retryCount++;
            this.connect();
          }, this.retryDelay);
        } else {
          //console.log('WebSocketManager: Max retry attempts reached');
        }
      };

      this.socket.onerror = (error) => {
        // console.error('WebSocketManager: WebSocket error:', error);
      };
    } catch (error) {
      console.error('WebSocketManager: Error creating WebSocket connection:', error);
    }
  }

  addMessageHandler(type, handler) {
    //console.log(`WebSocketManager: Adding message handler for type "${type}"`);
    const handlers = this.messageHandlers.get(type) || [];
    this.messageHandlers.set(type, [...handlers, handler]);
  }

  removeMessageHandler(type, handler) {
    //console.log(`WebSocketManager: Removing message handler for type "${type}"`);
    const handlers = this.messageHandlers.get(type) || [];
    this.messageHandlers.set(type, handlers.filter(h => h !== handler));
  }

  send(type, data) {
    const message = JSON.stringify({ type, ...data });
    //console.log('WebSocketManager: Attempting to send message:', message);

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      //console.log('WebSocketManager: Sending message');
      this.socket.send(message);
    } else {
      //console.log('WebSocketManager: Connection not ready, queueing message');
      this.pendingMessages.push({ type, data });
    }
  }

  disconnect() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      //console.log('WebSocketManager: Disconnecting');
      this.socket.close();
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    this.messageHandlers.clear();
    this.pendingMessages = [];
  }
}

export default WebSocketManager;
