import React, { useMemo, useEffect, useState } from 'react';
import ConditionSelect from './ConditionSelect';
import MultiSelect from './MultiSelect';
import DateRangeSlider from './DateRangeSlider';
import { X } from 'lucide-react';

const PlotFilters = ({
  startDate,
  onStartDateChange,
  sponsors,
  selectedSponsors,
  onSponsorsChange,
  targets,
  selectedTargets,
  onTargetsChange,
  modalities,
  selectedModalities,
  onModalitiesChange,
  conditions,
  selectedCondition,
  onConditionChange,
  trialLandscapeData,
}) => {
  const [filterByDateRange, setFilterByDateRange] = useState(false);
  const [shouldRenderSlider, setShouldRenderSlider] = useState(false);

  // Calculate available date range from trial data
  const availableDateRange = React.useMemo(() => {
    if (!trialLandscapeData?.length) {
      // Provide default date range instead of returning null
      return {
        minDate: new Date(2024, 0, 1).toISOString(), // January 1, 2024
        maxDate: new Date(2024, 11, 31).toISOString() // December 31, 2024
      };
    }

    const dates = trialLandscapeData.map(trial => new Date(trial.start_date));
    return {
      minDate: new Date(Math.min(...dates)).toISOString(),
      maxDate: new Date(Math.max(...dates)).toISOString()
    };
  }, [trialLandscapeData]);

  // Adjust date range when available range changes
  React.useEffect(() => {
    if (!availableDateRange) return;

    const newStartDate = new Date(availableDateRange.minDate);
    const newEndDate = new Date(availableDateRange.maxDate);
    const currentStart = new Date(startDate.start);
    const currentEnd = new Date(startDate.end);

    // Only update if the current range is outside the available range
    if (currentStart < newStartDate || currentEnd > newEndDate || currentStart > currentEnd) {
      onStartDateChange({
        start: availableDateRange.minDate,
        end: availableDateRange.maxDate
      });
    }
  }, [availableDateRange]);

  // Filter available options based on selected conditions
  const filteredOptions = useMemo(() => {
    if (!trialLandscapeData?.length) return { sponsors: [], targets: [], modalities: [] };

    // Filter trials by selected conditions and date range if enabled
    const relevantTrials = trialLandscapeData.filter(trial => {
      const matchesCondition = selectedCondition.length === 0 || 
        selectedCondition.includes(trial.condition);
      
      if (filterByDateRange) {
        const trialDate = new Date(trial.start_date);
        const matchesDate = trialDate >= new Date(startDate.start) && 
          trialDate <= new Date(startDate.end);
        return matchesCondition && matchesDate;
      }
      
      return matchesCondition;
    });

    // Extract unique values for each filter
    const availableSponsors = [...new Set(relevantTrials.flatMap(trial => 
      trial.sponsor_names?.split(';')
        .map(s => s?.trim())
        .filter(s => s != null && s !== '')
    ))];

    const availableTargets = [...new Set(relevantTrials
      .map(trial => trial.target_short)
      .filter(target => target != null && target !== '')
    )];

    const availableModalities = [...new Set(relevantTrials
      .map(trial => trial.modality)
      .filter(modality => modality != null && modality !== '')
    )];

    return {
      sponsors: availableSponsors,
      targets: availableTargets,
      modalities: availableModalities
    };
  }, [trialLandscapeData, selectedCondition, filterByDateRange, startDate]);

  // Clear selections that are no longer available when conditions change
  useEffect(() => {
    const newSponsors = selectedSponsors.filter(s => filteredOptions.sponsors.includes(s));
    if (newSponsors.length !== selectedSponsors.length) {
      onSponsorsChange(newSponsors);
    }

    const newTargets = selectedTargets.filter(t => filteredOptions.targets.includes(t));
    if (newTargets.length !== selectedTargets.length) {
      onTargetsChange(newTargets);
    }

    const newModalities = selectedModalities.filter(m => filteredOptions.modalities.includes(m));
    if (newModalities.length !== selectedModalities.length) {
      onModalitiesChange(newModalities);
    }
  }, [filteredOptions]);

  useEffect(() => {
    if (trialLandscapeData?.length > 0) {
      setShouldRenderSlider(true);
    }
  }, [trialLandscapeData]);

  return (
    <div className="space-y-8">
      {/* Disease Area Selection Section */}
      <div className="max-w-[1200px] bg-gray-50 p-6 rounded-lg">
        <h2 className="text-lg font-semibold text-gray-800 mb-3">Disease Area Selection</h2>
        <div className="grid grid-cols-3 gap-6">
          <div>
            <ConditionSelect
              selectedCondition={selectedCondition}
              conditions={conditions || []}
              onChange={onConditionChange}
            />
          </div>
          <div className="col-span-2"></div>
        </div>
        {selectedCondition.length > 0 && (
          <div className="mt-4 flex flex-wrap gap-2">
            {selectedCondition.map(condition => (
              <div
                key={condition}
                className="flex items-center gap-2 bg-white px-3 py-1.5 rounded-lg text-sm text-gray-700 hover:bg-gray-50 border border-gray-200 shadow-sm"
              >
                <span className="truncate max-w-[200px]">{condition}</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onConditionChange(selectedCondition.filter(c => c !== condition));
                  }}
                  className="flex-shrink-0 text-gray-400 hover:text-gray-600 focus:outline-none"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Filters Section */}
      <div className="max-w-[1200px] bg-gray-50 p-6 rounded-lg">
        <div className="mb-4 space-y-2">
          <h2 className="text-lg font-semibold text-gray-800">
            Filters within {selectedCondition.length === 0 ? 'All Disease Areas' : selectedCondition.length === 1 ? selectedCondition[0] : 'Selected Disease Areas'}
          </h2>
        </div>

        <div className="space-y-6">
          {/* Date Range Section - Moved to top */}
          <div className="space-y-4">
            <div className="w-[90%]">
              <div className="flex items-center gap-4">
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-700 whitespace-nowrap">Date range of trial start date:</span>
                  <span className="text-xs text-gray-500">(Can be in the future)</span>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="date"
                    value={startDate.start.split('T')[0]}
                    min={availableDateRange.minDate.split('T')[0]}
                    max={availableDateRange.maxDate.split('T')[0]}
                    onChange={(e) => onStartDateChange({
                      ...startDate,
                      start: new Date(e.target.value).toISOString()
                    })}
                    className="text-sm border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#852A45] focus:border-transparent"
                  />
                  <span className="text-sm text-gray-500">to</span>
                  <input
                    type="date"
                    value={startDate.end.split('T')[0]}
                    min={availableDateRange.minDate.split('T')[0]}
                    max={availableDateRange.maxDate.split('T')[0]}
                    onChange={(e) => onStartDateChange({
                      ...startDate,
                      end: new Date(e.target.value).toISOString()
                    })}
                    className="text-sm border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#852A45] focus:border-transparent"
                  />
                </div>
              </div>
              
              {shouldRenderSlider && (
                <DateRangeSlider
                  startDate={startDate.start}
                  endDate={startDate.end}
                  onChange={onStartDateChange}
                  minDate={availableDateRange.minDate}
                  maxDate={availableDateRange.maxDate}
                />
              )}

              <div className="flex items-center gap-2 text-sm font-medium text-gray-700 mt-2">
                <input
                  type="checkbox"
                  checked={filterByDateRange}
                  onChange={(e) => setFilterByDateRange(e.target.checked)}
                  className="rounded border-gray-300 text-[#852A45] focus:ring-[#852A45]"
                />
                <span>Check to show filter options for selected date range only</span>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            {/* Sponsors Filter */}
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <MultiSelect
                      label="Sponsors"
                      selected={selectedSponsors}
                      options={filteredOptions.sponsors}
                      onChange={onSponsorsChange}
                      placeholder="Select sponsors..."
                    />
                  </div>
                  <div className="col-span-2"></div>
                </div>
                {selectedSponsors.length > 0 && selectedSponsors.length !== filteredOptions.sponsors.length && (
                  <div className="flex flex-wrap gap-2">
                    {selectedSponsors.map(sponsor => (
                      <div
                        key={sponsor}
                        className="flex items-center gap-2 bg-white px-3 py-1.5 rounded-lg text-sm text-gray-700 hover:bg-gray-50 border border-gray-200 shadow-sm"
                      >
                        <span className="truncate max-w-[200px]">{sponsor}</span>
                        <button
                          onClick={() => onSponsorsChange(selectedSponsors.filter(s => s !== sponsor))}
                          className="flex-shrink-0 text-gray-400 hover:text-gray-600 focus:outline-none"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Targets Filter */}
              <div className="space-y-4">
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <MultiSelect
                      label="Targets"
                      selected={selectedTargets}
                      options={filteredOptions.targets}
                      onChange={onTargetsChange}
                      placeholder="Select targets..."
                    />
                  </div>
                  <div className="col-span-2"></div>
                </div>
                {selectedTargets.length > 0 && selectedTargets.length !== filteredOptions.targets.length && (
                  <div className="flex flex-wrap gap-2">
                    {selectedTargets.map(target => (
                      <div
                        key={target}
                        className="flex items-center gap-2 bg-white px-3 py-1.5 rounded-lg text-sm text-gray-700 hover:bg-gray-50 border border-gray-200 shadow-sm"
                      >
                        <span className="truncate max-w-[200px]">{target}</span>
                        <button
                          onClick={() => onTargetsChange(selectedTargets.filter(t => t !== target))}
                          className="flex-shrink-0 text-gray-400 hover:text-gray-600 focus:outline-none"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Modalities Filter */}
              <div className="space-y-4">
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <MultiSelect
                      label="Modalities"
                      selected={selectedModalities}
                      options={filteredOptions.modalities}
                      onChange={onModalitiesChange}
                      placeholder="Select modalities..."
                    />
                  </div>
                  <div className="col-span-2"></div>
                </div>
                {selectedModalities.length > 0 && selectedModalities.length !== filteredOptions.modalities.length && (
                  <div className="flex flex-wrap gap-2">
                    {selectedModalities.map(modality => (
                      <div
                        key={modality}
                        className="flex items-center gap-2 bg-white px-3 py-1.5 rounded-lg text-sm text-gray-700 hover:bg-gray-50 border border-gray-200 shadow-sm"
                      >
                        <span className="truncate max-w-[200px]">{modality}</span>
                        <button
                          onClick={() => onModalitiesChange(selectedModalities.filter(m => m !== modality))}
                          className="flex-shrink-0 text-gray-400 hover:text-gray-600 focus:outline-none"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlotFilters; 