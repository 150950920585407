import React, { useState } from 'react';
import { WebSocketProvider } from './context/WebSocketContext';
import { TargetsProvider } from './context/TargetsContext';
import TrialLandscape from './components/trial_landscape/TrialLandscape';
import Sidebar from './components/layout/Sidebar';
import MessageTab from './components/feedback/MessageTab';
import AboutTab from './components/about/AboutTab';
import Header from './components/layout/Header';

function App() {
  const [submittedData, setSubmittedData] = useState(null);
  const [activeTab, setActiveTab] = useState('landscape');
  const [internalTab, setInternalTab] = useState('plot');

  const handleUserSubmit = (data) => {
    setSubmittedData(data);
  };

  const handleTabChange = (tab) => {
    if (tab === 'landscape') {
      setActiveTab('landscape');
    } else {
      setActiveTab(tab);
    }
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'landscape':
        return <TrialLandscape activeTab={internalTab} onTabChange={setInternalTab} />;
      case 'feedback':
        return <MessageTab />;
      case 'about':
        return <AboutTab onTabChange={setActiveTab} />;
      default:
        return null;
    }
  };

  return (
    <TargetsProvider>
      <WebSocketProvider>
        <Header onTabChange={handleTabChange} />
        <div className="flex min-h-screen">
          <Sidebar 
            activeTab={activeTab} 
            onTabChange={handleTabChange}
            internalTab={internalTab}
            onInternalTabChange={setInternalTab}
          />
          <div className="flex-1">
            <main className="ml-1 min-w-0 mt-32">
              <div className="p-1 pr-12">
                {renderActiveTab()}
              </div>
            </main>
          </div>
        </div>
      </WebSocketProvider>
    </TargetsProvider>
  );
}

export default App;
