import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, Search, X } from 'lucide-react';

const ConditionSelect = ({ selectedCondition, conditions, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  
  // Convert single selectedCondition to array if it's not an array
  const selectedConditions = Array.isArray(selectedCondition) ? selectedCondition : [selectedCondition];

  useEffect(() => {
    if (!isOpen) return; // Only add listeners when dropdown is open

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]); // Add isOpen to dependencies

  // Focus search input when dropdown opens
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    } else {
      setSearchTerm('');
      setHighlightedIndex(-1);
    }
  }, [isOpen]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleConditionClick = (condition) => {
    let newSelected;
    if (selectedConditions.includes(condition)) {
      newSelected = selectedConditions.filter(c => c !== condition);
    } else {
      newSelected = [...selectedConditions, condition];
    }
    
    onChange(newSelected);
  };

  const handleRemoveCondition = (e, condition) => {
    e.stopPropagation(); // Prevent dropdown from toggling
    handleConditionClick(condition);
  };

  const getDisplayContent = () => {
    if (selectedConditions.length === 0) {
      return <span className="text-sm text-gray-500">Select conditions...</span>;
    }
    return <span className="text-sm text-gray-700">{selectedConditions.length} conditions selected</span>;
  };

  const filteredConditions = conditions.filter(condition => 
    condition && condition.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < filteredConditions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredConditions.length) {
          handleConditionClick(filteredConditions[highlightedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleSelectAll = () => {
    onChange(conditions);
  };

  const handleDeselectAll = () => {
    onChange([]);
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div 
        className="flex items-center gap-2 h-[56px] p-4 bg-white rounded-lg shadow-md cursor-pointer hover:bg-gray-50 border-2 border-[#852A45]/20 hover:border-[#852A45]/40 transition-colors"
        onClick={toggleDropdown}
      >
        <label className="text-sm font-medium text-gray-700 whitespace-nowrap min-w-[120px]">
          Disease Area:
        </label>
        <div className="flex items-center gap-2 min-w-0 flex-1">
          {getDisplayContent()}
          <div className="flex-shrink-0">
            {isOpen ? (
              <ChevronUp className="h-5 w-5 text-[#852A45]" />
            ) : (
              <ChevronDown className="h-5 w-5 text-[#852A45]" />
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg overflow-hidden border border-[#852A45]/20">
          <div className="p-2 border-b">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                ref={searchInputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setHighlightedIndex(-1);
                }}
                onKeyDown={handleKeyDown}
                placeholder="Search conditions..."
                className="w-full pl-9 pr-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-[#852A45] focus:border-transparent"
              />
            </div>
            
            <div className="flex gap-2 mt-2">
              <button
                onClick={handleSelectAll}
                className="px-2 py-0.5 text-xs text-[#852A45] bg-white border border-[#852A45] rounded hover:bg-[#852A45] hover:text-white transition-colors"
              >
                Select All
              </button>
              <button
                onClick={handleDeselectAll}
                className="px-2 py-0.5 text-xs text-[#852A45] bg-white border border-[#852A45] rounded hover:bg-[#852A45] hover:text-white transition-colors"
              >
                Deselect All
              </button>
            </div>
          </div>
          
          <div className="max-h-60 overflow-y-auto">
            {filteredConditions.map((condition, index) => (
              <div
                key={condition}
                className={`p-2 cursor-pointer flex items-center gap-2 ${
                  highlightedIndex === index ? 'bg-gray-100' : 'hover:bg-gray-50'
                }`}
                onClick={() => handleConditionClick(condition)}
                onMouseEnter={() => setHighlightedIndex(index)}
              >
                <div className={`w-4 h-4 border rounded flex items-center justify-center ${
                  selectedConditions.includes(condition)
                    ? 'bg-[#852A45] border-[#852A45]'
                    : 'border-gray-300'
                }`}>
                  {selectedConditions.includes(condition) && (
                    <svg className="w-3 h-3 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  )}
                </div>
                <span className="text-sm">{condition}</span>
              </div>
            ))}
            {filteredConditions.length === 0 && (
              <div className="p-2 text-sm text-gray-500 text-center">
                No matching conditions found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ConditionSelect.propTypes = {
  selectedCondition: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  conditions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired
};

export default ConditionSelect;
