import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, Search, X } from 'lucide-react';

const MultiSelect = ({ 
  label, 
  selected = [], 
  options = [], 
  onChange,
  placeholder = "Select options..."
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    } else {
      setSearchTerm('');
      setHighlightedIndex(-1);
    }
  }, [isOpen]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    let newSelected;
    if (selected.includes(option)) {
      newSelected = selected.filter(item => item !== option);
    } else {
      newSelected = [...selected, option];
    }
    onChange(newSelected);
  };

  const handleRemoveOption = (e, option) => {
    e.stopPropagation();
    handleOptionClick(option);
  };

  const getDisplayContent = () => {
    if (selected.length === 0) {
      return <span className="text-sm text-gray-500">{placeholder}</span>;
    }

    if (selected.length === options.length) {
      return <span className="text-sm text-gray-700">All {label.toLowerCase()}</span>;
    }

    return <span className="text-sm text-gray-700">{selected.length} {label.toLowerCase()} selected</span>;
  };

  const filteredOptions = options.filter(option => 
    option && option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < filteredOptions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredOptions.length) {
          handleOptionClick(filteredOptions[highlightedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleSelectAll = () => {
    onChange(options);
  };

  const handleDeselectAll = () => {
    onChange([]);
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div 
        className="flex items-center gap-2 h-[56px] p-4 bg-white rounded-lg shadow-md cursor-pointer hover:bg-gray-50 border-2 border-[#852A45]/20 hover:border-[#852A45]/40 transition-colors"
        onClick={toggleDropdown}
      >
        <label className="text-sm font-medium text-gray-700 whitespace-nowrap min-w-[120px]">
          {label}:
        </label>
        <div className="flex items-center gap-2 min-w-0 flex-1">
          {getDisplayContent()}
          <div className="flex-shrink-0">
            {isOpen ? (
              <ChevronUp className="h-5 w-5 text-[#852A45]" />
            ) : (
              <ChevronDown className="h-5 w-5 text-[#852A45]" />
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg overflow-hidden border border-[#852A45]/20">
          <div className="p-2 border-b">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                ref={searchInputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setHighlightedIndex(-1);
                }}
                onKeyDown={handleKeyDown}
                placeholder={`Search ${label.toLowerCase()}...`}
                className="w-full pl-9 pr-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-[#852A45] focus:border-transparent"
              />
            </div>
            
            <div className="flex gap-2 mt-2">
              <button
                onClick={handleSelectAll}
                className="px-2 py-0.5 text-xs text-[#852A45] bg-white border border-[#852A45] rounded hover:bg-[#852A45] hover:text-white transition-colors"
              >
                Select All
              </button>
              <button
                onClick={handleDeselectAll}
                className="px-2 py-0.5 text-xs text-[#852A45] bg-white border border-[#852A45] rounded hover:bg-[#852A45] hover:text-white transition-colors"
              >
                Deselect All
              </button>
            </div>
          </div>
          
          <div className="max-h-60 overflow-y-auto">
            {filteredOptions.map((option, index) => (
              <div
                key={option}
                className={`p-2 cursor-pointer flex items-center gap-2 ${
                  highlightedIndex === index ? 'bg-gray-100' : 'hover:bg-gray-50'
                }`}
                onClick={() => handleOptionClick(option)}
                onMouseEnter={() => setHighlightedIndex(index)}
              >
                <div className={`w-4 h-4 border rounded flex items-center justify-center ${
                  selected.includes(option)
                    ? 'bg-[#852A45] border-[#852A45]'
                    : 'border-gray-300'
                }`}>
                  {selected.includes(option) && (
                    <svg className="w-3 h-3 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  )}
                </div>
                <span className="text-sm">{option}</span>
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className="p-2 text-sm text-gray-500 text-center">
                No matching options found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelect; 