import React, { useState, useMemo, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import FeedbackButton from './FeedbackButton';
import { FaEllipsisH } from 'react-icons/fa';
import Tooltip from '../../common/Tooltip';

const getTextWidth = (text) => {
  return text.length * 6; // Approximate width based on character count
};

const checkLabelOverlap = (pos1, text1, pos2, text2) => {
  const w1 = getTextWidth(text1);
  const w2 = getTextWidth(text2);
  const h = 15; // Text height
  
  const x1 = pos1.x - (pos1.anchor === 'end' ? w1 : pos1.anchor === 'middle' ? w1/2 : 0);
  const x2 = pos2.x - (pos2.anchor === 'end' ? w2 : pos2.anchor === 'middle' ? w2/2 : 0);
  
  return Math.abs(pos1.y - pos2.y) < h && 
         Math.abs(x1 - x2) < (w1 + w2) / 2;
};

export const CircularSections = ({ centerX, centerY, phaseConfig }) => (
  <>
    {/* Render phase circles and lines first (bottom layer) */}
    {Object.entries(phaseConfig)
      .filter(([phase]) => phase !== 'center')
      .map(([phase, config]) => (
        <circle
          key={phase}
          cx={centerX}
          cy={centerY}
          r={config.outerRadius}
          fill="none"
          stroke="#d0d0d0"
          strokeWidth="1"
        />
      ))}
  </>
);

export const RadialLines = ({ targets, centerX, centerY }) => (
  targets.map((target, index) => {
    const angle = target.startAngle;
    const radianAngle = (angle * Math.PI) / 180;
    const endX = centerX + Math.cos(radianAngle) * 370; // Rmax (350) + 20
    const endY = centerY + Math.sin(radianAngle) * 370; // Rmax (350) + 20

    return (
      <line
        key={`radial-line-${index}`}
        x1={centerX}
        y1={centerY}
        x2={endX}
        y2={endY}
        stroke="#d0d0d0"
        strokeWidth="1"
        strokeDasharray="4 4"
      />
    );
  })
);

export const PhaseLabels = ({ centerX, centerY, phaseConfig }) => (
  Object.entries(phaseConfig)
    .filter(([phase]) => phase !== 'center')
    .map(([phase, config]) => {
      const { innerRadius, outerRadius } = config;
      const labelRadius = (innerRadius + outerRadius) / 2;

      return (
        <g key={phase}>
          {/* Background for better readability */}
          <rect
            x={centerX - 30}
            y={centerY - labelRadius - 10}
            width={60}
            height={20}
            fill="white"
            fillOpacity={0.8}
          />
          <text
            x={centerX}
            y={centerY - labelRadius}
            textAnchor="middle"
            className="text-sm font-semibold fill-gray-700"
            dominantBaseline="middle"
          >
            {phase}
          </text>
        </g>
      );
    })
);

export const Legend = ({ x, y, width, modalities, modalityColors }) => (
  <div 
    style={{
      position: 'absolute',
      left: x,
      top: y,
      width: width,
      backgroundColor: 'white',
      border: '1px solid #d0d0d0',
      borderRadius: '4px',
      padding: '10px',
      zIndex: 10
    }}
  >
    <div className="text-sm font-semibold mb-2">Modalities</div>
    {modalities.map((modality, index) => (
      <div key={modality} className="flex items-center gap-2 mb-2">
        <div 
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: modalityColors[modality]
          }}
        />
        <span className="text-xs">{modality}</span>
      </div>
    ))}
  </div>
);

export const TargetLabels = ({ targets, centerX, centerY, Rmax, labelPositions }) => (
  targets.map((target, index) => {
    const nextTarget = targets[(index + 1) % targets.length];
    const angleToNext = ((nextTarget.startAngle - target.startAngle + 360) % 360);
    const offset = angleToNext / 2;
    const labelAngle = (target.startAngle + offset) % 360;
    const radianAngle = (labelAngle * Math.PI) / 180;
    
    // Reduced label distance from circle
    const labelDistance = 30;  // Reduced from 40
    const endX = centerX + Math.cos(radianAngle) * (Rmax + labelDistance);
    const endY = centerY + Math.sin(radianAngle) * (Rmax + labelDistance);

    // Split text into words
    const words = target.name.split(' ');
    const CHARS_PER_LINE = 12;  // Reduced from 15
    let lines = [];
    let currentLine = words[0];

    // Create more compact lines
    for(let i = 1; i < words.length; i++) {
      if (currentLine.length + words[i].length + 1 <= CHARS_PER_LINE) {
        currentLine += ' ' + words[i];
      } else {
        lines.push(currentLine);
        currentLine = words[i];
      }
    }
    lines.push(currentLine);

    return (
      <g key={target.name}>
        {lines.map((line, lineIndex) => (
          <text
            key={`${target.name}-${lineIndex}`}
            x={endX}
            y={endY + (lineIndex * 14 - (lines.length - 1) * 7)} // Reduced line spacing
            textAnchor="middle"
            className="text-sm fill-gray-700 font-medium"
            style={{ maxWidth: '100px' }} // Reduced from 120px
          >
            {line}
          </text>
        ))}
      </g>
    );
  })
);

export const TrialMarkers = ({
  trials,
  hoveredTrial,
  clickedTrial,
  setHoveredTrial,
  setClickedTrial,
  onSuggestChanges,
  modalityColors
}) => {
  const markerRefs = useRef({});
  const existingLabels = [];
  
  const handleTrialClick = (trial, e) => {
    e.stopPropagation();
    setClickedTrial(trial);
  };

  // Calculate label positions to avoid overlap
  const getLabelPosition = (trial, index, existingLabels) => {
    const LABEL_DISTANCE = 15;
    const radians = (trial.angle * Math.PI) / 180;
    
    // Try different positions in order of preference
    const positions = [
      // Radial position
      {
        x: trial.x + Math.cos(radians) * LABEL_DISTANCE,
        y: trial.y + Math.sin(radians) * LABEL_DISTANCE,
        anchor: trial.angle > 90 && trial.angle < 270 ? 'end' : 'start',
        baseline: 'middle'
      },
      // Above
      {
        x: trial.x,
        y: trial.y - LABEL_DISTANCE,
        anchor: 'middle',
        baseline: 'baseline'
      },
      // Below
      {
        x: trial.x,
        y: trial.y + LABEL_DISTANCE,
        anchor: 'middle',
        baseline: 'hanging'
      },
      // Left
      {
        x: trial.x - LABEL_DISTANCE,
        y: trial.y,
        anchor: 'end',
        baseline: 'middle'
      },
      // Right
      {
        x: trial.x + LABEL_DISTANCE,
        y: trial.y,
        anchor: 'start',
        baseline: 'middle'
      }
    ];

    // Check for phase label overlaps
    const isNearPhaseLabel = (pos) => {
      const dy = pos.y - 400; // center Y
      const dx = pos.x - 500; // center X
      const distanceFromCenter = Math.sqrt(dx * dx + dy * dy);
      const angle = Math.atan2(dy, dx) * 180 / Math.PI;
      
      // Check if near any phase label
      return Math.abs(angle + 90) < 30 && distanceFromCenter > 100;
    };

    // Try each position until we find one without overlaps
    for (const pos of positions) {
      if (isNearPhaseLabel(pos)) continue;
      
      let hasOverlap = false;
      for (const existing of existingLabels) {
        if (checkLabelOverlap(pos, trial.investigational_agent, existing.pos, existing.text)) {
          hasOverlap = true;
          break;
        }
      }
      
      if (!hasOverlap) {
        return pos;
      }
    }
    
    // If all positions have overlaps, return the radial position
    return positions[0];
  };

  useEffect(() => {
    setClickedTrial(null);
  }, [trials, setClickedTrial]);
  
  return (
    <>
      <g key={trials.map(t => t.id || t.nct_id).join('-')}>
        {trials.map((trial, index) => {
          const isActive = hoveredTrial === trial || clickedTrial === trial;
          const markerKey = trial.id || trial.nct_id;
          const labelPos = getLabelPosition(trial, index, existingLabels);
          existingLabels.push({
            pos: labelPos,
            text: trial.investigational_agent
          });
          
          return (
            <g 
              key={markerKey}
              className="trial-marker"
              ref={el => {
                if (el) {
                  markerRefs.current[markerKey] = el;
                } else {
                  delete markerRefs.current[markerKey];
                }
              }}
            >
              {/* Add invisible larger circle for better click target */}
              <circle
                cx={trial.x}
                cy={trial.y}
                r="10"
                fill="transparent"
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setHoveredTrial(trial)}
                onMouseLeave={() => setHoveredTrial(null)}
                onClick={(e) => handleTrialClick(trial, e)}
              />
              {/* Visible marker circle */}
              <circle
                cx={trial.x}
                cy={trial.y}
                r={isActive ? "6" : "5"}
                fill={modalityColors[trial.modality]}
                stroke="white"
                strokeWidth={isActive ? "2" : "1"}
                style={{ pointerEvents: 'none' }}
              />
              {/* Label text */}
              <text
                x={labelPos.x}
                y={labelPos.y}
                textAnchor={labelPos.anchor}
                dominantBaseline={labelPos.baseline}
                className="text-xs fill-gray-600"
                style={{
                  opacity: isActive ? 1 : 0.8,
                  pointerEvents: 'none'
                }}
              >
                {trial.investigational_agent}
              </text>
            </g>
          );
        })}
      </g>
      {clickedTrial && (
        <TooltipContainer 
          trial={clickedTrial}
          markerRef={markerRefs.current[clickedTrial.id || clickedTrial.nct_id]}
          onClose={() => setClickedTrial(null)}
          onSuggestChanges={onSuggestChanges}
        />
      )}
    </>
  );
};

const TooltipContainer = ({ trial, markerRef, onClose, onSuggestChanges }) => {
  const [position, setPosition] = useState(null);
  
  useEffect(() => {
    if (markerRef) {
      const rect = markerRef.getBoundingClientRect();
      setPosition({
        left: rect.left + window.scrollX + rect.width / 2,
        top: rect.top + window.scrollY
      });
    }
  }, [markerRef]);

  if (!position) return null;

  return ReactDOM.createPortal(
    <div 
      style={{ 
        position: 'absolute',
        left: position.left + 20,
        top: position.top - 20,
        zIndex: 1000,
        transition: 'all 0.2s ease-out',
        opacity: position ? 1 : 0,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Tooltip
        data={{
          investigational_agent: trial.investigational_agent,
          phase: trial.phase,
          target: trial.target,
          modality: trial.modality,
          sponsor_names: trial.sponsor_names,
          nct_id: trial.nct_id
        }}
        isVisible={true}
        onClose={onClose}
        onSuggestChanges={onSuggestChanges}
      />
    </div>,
    document.body
  );
};

export const TargetCountControl = ({ targetCount, setTargetCount }) => (
  <div>
    <label htmlFor="targetCount" className="mr-2">
      Number of Targets:
    </label>
    <input
      id="targetCount"
      type="number"
      min="1"
      max="20"
      value={targetCount}
      onChange={(e) => setTargetCount(Number(e.target.value))}
      className="border p-1 w-16"
    />
  </div>
);

export const CenterCircle = ({ centerX, centerY, phaseConfig }) => (
  <circle
    cx={centerX}
    cy={centerY}
    r={50}
    fill="#D0EBFF"
    stroke="#ccc"
    strokeWidth="1"
  />
);
