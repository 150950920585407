import React, { useState, useContext } from 'react';
import { WebSocketContext } from '../../context/WebSocketContext';

const MessageTab = () => {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const { sendMessage } = useContext(WebSocketContext);

  const handleSubmit = async () => {
    if (!email || !message) {
      setStatus('error');
      return;
    }

    try {
      sendMessage('send_message', {
        message: message,
        email: email
      });
      setStatus('success');
      setMessage('');
      setEmail('');
      setTimeout(() => {
        setStatus('');
      }, 2000);
    } catch (error) {
      console.error('Failed to send message:', error);
      setStatus('error');
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-semibold text-[#852A45] mb-4">Get in Touch</h2>

        
        <div className="mb-6">
          <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">
            Your Email Address
          </label>
          <input
            id="email"
            type="email"
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#852A45] focus:border-[#852A45] outline-none transition-all"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-6">
          <label htmlFor="message" className="block text-gray-700 text-sm font-medium mb-2">
            Your Message
          </label>
          <textarea
            id="message"
            className="w-full p-3 border rounded-lg h-48 focus:ring-2 focus:ring-[#852A45] focus:border-[#852A45] outline-none transition-all"
            placeholder="Type your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>

        {status === 'error' && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg">
            {!email || !message 
              ? "Please fill in all fields" 
              : "Failed to send message. Please try again."}
          </div>
        )}

        {status === 'success' && (
          <div className="mb-4 p-3 bg-green-50 text-green-700 rounded-lg">
            Message sent successfully!
          </div>
        )}

        <button
          className="w-full bg-[#852A45] text-white py-3 px-6 rounded-lg hover:bg-[#6d2238] transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#852A45]"
          onClick={handleSubmit}
        >
          Send Message
        </button>
      </div>
    </div>
  );
};

export default MessageTab;
