import React, { useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WebSocketContext } from '../../context/WebSocketContext';
import TargetTrialPhasePlot from './components/TargetTrialPhasePlot';
import TrialDataTable from './components/TrialDataTable';
import ConditionSelect from './components/ConditionSelect';
import useDrugLandscapeData from './hooks/useDrugLandscapeData';
import PlotFilters from './components/PlotFilters';

// // Export trial landscape data to CSV
// const exportToCSV = (data) => {
//   if (!data || data.length === 0) return;

//   // Define CSV headers based on the data structure
//   const headers = Object.keys(data[0]);
//   const csvContent = [
//     headers.join(','), // CSV header row
//     ...data.map(row => 
//       headers.map(header => {
//         // Handle values that might contain commas
//         const value = row[header]?.toString() || '';
//         return value.includes(',') ? `"${value}"` : value;
//       }).join(',')
//     )
//   ].join('\n');

//   // Create a Blob containing the CSV data
//   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//   const url = URL.createObjectURL(blob);
  
//   // Create a temporary link element and trigger download
//   const link = document.createElement('a');
//   link.setAttribute('href', url);
//   link.setAttribute('download', 'trial_landscape_data.csv');
//   document.body.appendChild(link);
//   link.click();
  
//   // Clean up
//   document.body.removeChild(link);
//   URL.revokeObjectURL(url);
// };

const CONTENT_WIDTH = 1200; // Increased to use more space

const LoadingMessage = () => (
  <div className="flex justify-center items-center h-64 w-full">
    <div className="text-gray-500 text-lg">
      Loading trial landscape data...
    </div>
  </div>
);

const TrialLandscape = ({ activeTab, onTabChange }) => {
  const { trialLandscapeData } = useContext(WebSocketContext);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [targetCount] = useState(8);
  const [dateRange, setDateRange] = useState({
    start: new Date(2024, 0, 1).toISOString(), // January 1, 2024
    end: new Date(2024, 11, 31).toISOString()  // December 31, 2024
  });
  const [selectedSponsors, setSelectedSponsors] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [selectedModalities, setSelectedModalities] = useState([]);

  // Store all available conditions separately from filtered data
  const allConditions = useMemo(() => {
    return trialLandscapeData?.length 
      ? [...new Set(trialLandscapeData.map(trial => trial.condition))]
      : [];
  }, [trialLandscapeData]);

  // Use this for filtering
  const conditions = useMemo(() => {
    return allConditions;
  }, [allConditions]);

  useEffect(() => {
    // Only run once when conditions are first loaded
    if (conditions.length > 0 && !hasInitialized) {
      const cancerConditions = conditions.filter(condition => 
        condition.toLowerCase().includes('cancer') || 
        condition.toLowerCase().includes('tumor') ||
        condition.toLowerCase().includes('carcinoma')
      );
      
      if (cancerConditions.length > 0) {
        setSelectedCondition([cancerConditions[0]]);
      } else {
        setSelectedCondition([conditions[0]]);
      }
      setHasInitialized(true);
    }
  }, [conditions, hasInitialized]);

  const sponsors = useMemo(() => {
    return trialLandscapeData?.length 
      ? [...new Set(trialLandscapeData.flatMap(trial => 
          trial.sponsor_names?.split(';')
            .map(s => s?.trim())
            .filter(s => s != null && s !== '')
        ))]
      : [];
  }, [trialLandscapeData]);

  const availableTargets = useMemo(() => {
    return trialLandscapeData?.length 
      ? [...new Set(trialLandscapeData
          .map(trial => trial.target_short)
          .filter(target => target != null && target !== '')
        )]
      : [];
  }, [trialLandscapeData]);

  const trialsByCondition = useMemo(() => {
    if (!trialLandscapeData?.length || !dateRange) return [];
    
    return trialLandscapeData.filter(trial => {
      const matchesCondition = selectedCondition.length > 0 && 
        selectedCondition.includes(trial.condition);
      
      const startDate = new Date(trial.start_date);
      const matchesDate = startDate >= new Date(dateRange.start) && 
        startDate <= new Date(dateRange.end);
      
      const matchesSponsor = selectedSponsors.length === 0 || 
        selectedSponsors.some(sponsor => 
          trial.sponsor_names?.includes(sponsor)
        );
      
      const matchesTarget = selectedTargets.length === 0 || 
        selectedTargets.includes(trial.target_short);
      
      const matchesModality = selectedModalities.length === 0 || 
        selectedModalities.includes(trial.modality);
      
      return matchesCondition && matchesDate && matchesSponsor && 
        matchesTarget && matchesModality;
    });
  }, [trialLandscapeData, selectedCondition, dateRange, selectedSponsors, 
      selectedTargets, selectedModalities]);

  const { 
    targets, 
    targetTrialsSelected,
    allTrialData,
    modalities, 
    modalityColors, 
    loading 
  } = useDrugLandscapeData(trialsByCondition, targetCount);

  //console.log('All trial data from hook:', allTrialData); // Debug log

  // Expose setActiveTab to window for Sidebar access
  useEffect(() => {
    window.setInternalTab = (tab) => onTabChange(tab);
    return () => {
      delete window.setInternalTab;
    };
  }, []);

  return (
    <div className="space-y-4">
      {dateRange && (
        <PlotFilters
          startDate={dateRange}
          onStartDateChange={setDateRange}
          sponsors={sponsors}
          selectedSponsors={selectedSponsors}
          onSponsorsChange={setSelectedSponsors}
          targets={availableTargets}
          selectedTargets={selectedTargets}
          onTargetsChange={setSelectedTargets}
          modalities={modalities}
          selectedModalities={selectedModalities}
          onModalitiesChange={setSelectedModalities}
          conditions={allConditions}
          selectedCondition={selectedCondition}
          onConditionChange={(newConditions) => {
            setSelectedCondition(newConditions);
            if (newConditions.length === 0) {
              setSelectedSponsors([]);
              setSelectedTargets([]);
              setSelectedModalities([]);
            }
          }}
          trialLandscapeData={trialLandscapeData}
        />
      )}
      
      <div className="w-full">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <button
              onClick={() => onTabChange('plot')}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none ${
                activeTab === 'plot'
                  ? 'border-[#852A45] text-[#852A45]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Target Landscape
            </button>
            <button
              onClick={() => onTabChange('table')}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none ${
                activeTab === 'table'
                  ? 'border-[#852A45] text-[#852A45]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Trials Table
            </button>
          </nav>
        </div>

        <div className="mt-6 flex justify-start pr-4">
          <div style={{ width: CONTENT_WIDTH }}>
            {loading ? (
              <LoadingMessage />
            ) : (
              <>
                {activeTab === 'plot' && (
                  <TargetTrialPhasePlot
                    targets={targets}
                    trialData={targetTrialsSelected}
                    modalities={modalities}
                    modalityColors={modalityColors}
                    loading={loading}
                    containerWidth={CONTENT_WIDTH}
                  />
                )}
                {activeTab === 'table' && (
                  <TrialDataTable
                    trialData={allTrialData}
                    loading={loading}
                    width={CONTENT_WIDTH}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TrialLandscape.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default TrialLandscape;
